
import { StorageService } from 'src/app/service/storage.service';
import { ModalController, AlertController } from '@ionic/angular';

import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { FCM } from '@capacitor-community/fcm';

import {
  ActionPerformed,
  PushNotifications,
} from '@capacitor/push-notifications';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private token: BehaviorSubject<any>;
  invoice: any;
  constructor(
    public modalController: ModalController,
    public router: Router,
    public storageService: StorageService,
    public alertController: AlertController,


  ) {


  }

  initPush() {
    if (Capacitor.getPlatform() !== 'web') {
     this.registerPush();
    } else {
      //  this.registerFire();
    }


  }
public registerTopic(profile){
  if (Capacitor.getPlatform() !== 'web') {
    FCM.subscribeTo({ topic: profile })
    //.then((r) => alert("registrou"+profile) )
    .catch((err) => console.log(err));
   } else {
     //  this.registerFire();
   }
   console.log("Registrou como: ",profile);
   

}

  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive === 'granted') {
        PushNotifications.register();
        console.log("registrou")
        // now you can subscribe to a specific topic
        FCM.subscribeTo({ topic: 'ALL' })
          .then((r) => '')
          .catch((err) => console.log(err));
      } else {
        // If permission is not granted
      }
    });



    PushNotifications.addListener('registration', (token) => {
console.log(token);



    });
    PushNotifications.addListener('registrationError', (err) => {
      console.log(err);
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.log('App Aberto Recebida' + notification);
        //  alert(JSON.stringify(notifications));

        const data = notification.data;



        if (data.tipo == 'invoice') {
          this.openDetails(this.invoice);
        } else {
          const info = notification;
          this.Alert(info.body, info.title, info.data);
        }
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        this.Alert(data.body)
        console.log('Action performed: ' + JSON.stringify(data));
        this.openDetails(data);

        if (data.tipo == 'invoice') {
          this.openDetails(data.invoice);
        } else {
          const info = notification.notification;
          this.Alert(info.body, info.title, info.data);
        }
      }
    );
  }





  async openDetails(item) {
    this.router.navigateByUrl('/notifications', { replaceUrl: true });

    // const modal = await this.modalController.create({
    //   component: '',
    //   cssClass: 'cart-modal',
    //   componentProps: { item },
    // });
    // modal.onDidDismiss().then((dataReturned) => { });
    // return await modal.present();
  }

  getToken() {
    if (Capacitor.getPlatform() !== 'web') {
     
    FCM.getToken().then(result => {

      console.log("Token :", result);
      this.storageService.setObject('token', result.token);

    }

    )
     } else {
       //  this.registerFire();
     }


  }

  async Alert(texto = null, titulo = null, data = null) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: titulo,
      message: texto,
      buttons: [
        {
          text: 'ok',
          handler: () => {
            if (data.tipo == 'invoice') {
              this.openDetails(data.invoice);
            } else {
            
              
            }
          },
        },
      ],
    });

    await alert.present();
  }
}
