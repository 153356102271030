import { ApiService } from './../../service/api.service';
import { StorageService } from './../../service/storage.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { Router } from '@angular/router';
import { AuthLoginService } from 'src/app/service/auth-login.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
  user: any;
  menuList: any;
  name: any;
  constructor(
    private storageService: StorageService,
    public modalController: ModalController,
    public authService: ApiService,
    public serviceLogin: AuthLoginService,
    private auth: AuthenticationService,
    private router: Router,
  ) {}

  async ngOnInit() {
    // this.Menu();
    const user = await this.storageService.getObject('summer');
    this.user = user;

    this.starMenu(user);
  }

  starMenu(dados: any) {
    console.log(dados);
    
    //   console.log(this.userDetails);
    this.menuList = null;

    
    this.authService.postTenant(dados, 'menuApp').then(
      (result) => {
        this.menuList = result;
        
        console.log(this.menuList);
      },
      (err) => {}
    );
  }
  async closeModal() {
    this.modalController.dismiss();
  }
  async logout() {
    await this.auth.logout();
    await this.serviceLogin.logout();
    this.closeModal();
    this.router.navigateByUrl('/login', { replaceUrl: true });
  }
}
