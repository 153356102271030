import { StorageService } from './service/storage.service';
import { filter, take, map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Platform } from '@ionic/angular';
import { ApiService } from 'src/app/service/api.service';

import { EventsService } from 'src/app/service/events-service.service';
import { NavigationEnd, Router } from '@angular/router';
import { timer, interval, Subscription, Observable } from "rxjs";


import { AlertController } from '@ionic/angular';
import { NotificationsService } from './service/notifications.service';
import { MessagingService } from './service/messaging.service';




@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isLoggedIn: boolean = true;
  menuList: any;
  database: any;
  userDetails: any;
  profile: string;
  user = {
    "user": "",
    "NomeCompleto": "",
    "email": "",
    "foto": "",
    "CodCliente": "",
    "idColaborador": "",
    "token": "",
    "TipoUsuario": ""
  };
  constructor(
    public serviceApi: ApiService,
    public authService: AuthenticationService,
    public pushNotifications: NotificationsService,
    public storageService: StorageService,
    private platform: Platform,
    private events: EventsService,
    private router: Router,
    private messagingService: MessagingService,
    public alertController: AlertController,

  ) {
    this.pushNotifications.initPush();
    this.logged();
    this.initializeApp();

    this.getMessagingToken();
  }



  async logged() {
    //await  this.authService.isLoggedIn();

    const login = await this.authService.isLoggedIn();

    this.authService.isLoggedIn().subscribe(val =>
      //console.log(val)
      this.isLoggedIn = val
    )





  }
  initializeApp() {
    this.platform.ready().then(() => {
    });

    // this.isLoggedIn =  this.logado();



  }
  getMessagingToken() {
    this.messagingService.getMessagingToken().subscribe(token => {
    //  console.log('Token do dispositivo:', token);

      this.storageService.setObject('token', token);

    });
  }




}
