import {Injectable} from '@angular/core';
import {Subject,Subscription} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private fooSubject = new Subject<any>();

  publishSomeData(data: any) {
      this.fooSubject.next(data);
  }

  getObservable(): Subject<any> {
      return this.fooSubject;
  }
  pubLogout(data: any) {
    this.fooSubject.next(data);
}
}
