import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';

import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatToolbarModule} from '@angular/material/toolbar';

import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSortModule} from '@angular/material/sort';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';

import {MatPaginatorModule} from '@angular/material/paginator';




@NgModule({
  exports: [
    IonicModule,
    MatTableModule,MatExpansionModule,
    MatMenuModule, MatNativeDateModule,MatSelectModule,
    MatFormFieldModule,MatInputModule,MatIconModule,
    MatDatepickerModule,MatToolbarModule, MatRadioModule,
    MatGridListModule,MatCardModule,MatAutocompleteModule,MatDividerModule,
    MatButtonModule,MatCheckboxModule,MatListModule,
    MatProgressBarModule,MatSortModule,MatSlideToggleModule,MatPaginatorModule
    
    
  ],
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [

    { provide: MAT_DATE_LOCALE,
      useValue: {
        display: {
          dateInput: 'pt-BR',
        }
      }
     },

  ]
})
export class MaterialModule { }
