

import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../service/authentication.service';
import { filter, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {

    constructor(private auth: AuthenticationService, private router: Router) { }
   
    canLoad(): Observable<boolean> {    
      return this.auth.isAuthenticated.pipe(
        filter(val => val !== null), // Filter out initial Behaviour subject value
        take(1), // Otherwise the Observable doesn't complete!
        map(isAuthenticated => {
         
          if (isAuthenticated) {
            // Directly open inside area     
        //   console.log('Autenticado');  
            this.router.navigateByUrl('/home', { replaceUrl: true });
          } else {          
     
            console.log('Não Autenticado');
            
            return true;
          }
        })
      );
    }
  }