

import { AlertController } from '@ionic/angular';
import { LoaderService } from 'src/app/service/loader.service';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

//import { TenantAccessService } from './tenant-access.service';



let token = '';
@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnInit {
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}
  apiBase: any;

  tenant_id = 2 ;
  constructor(
    public http: HttpClient,
    private alertController: AlertController,
    private router: Router, private ionLoader: LoaderService,


  ) { }
  async ngOnInit() {

  }
  postData(credentials, type) {
    return new Promise((resolve, reject) => {


      const headers = new HttpHeaders({
        'X-Tenant-ID':  this.tenant_id.toString() 
      });


      this.ionLoader.presentLoader();
      this.http.post(environment.apiUrl + type, credentials, { headers: headers })
        .subscribe(res => {
          this.ionLoader.hideLoader();
          resolve(res);


        }, (err) => {
          reject(err);
          this.Alert(err.message)

          this.ionLoader.hideLoader();

        });
    });
  }
 


  async  deleteTenant(item) {
    return new Promise((resolve, reject) => {
    const headers = new HttpHeaders({
        'X-Tenant-ID':   this.tenant_id.toString() 
    });
    this.ionLoader.presentLoader();
      this.http.delete(environment.apiUrl + item, { headers: headers })
      .subscribe(res => {
        this.ionLoader.hideLoader();
        resolve(res);
      }, (err) => {
        this.ionLoader.hideLoader();
        reject(err);

      });
  });
 }
  


 async  getTenant(item) {
      return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
          'X-Tenant-ID':   this.tenant_id.toString() 
      });
      
      
      this.ionLoader.presentLoader();
        this.http.get(environment.apiUrl + item, { headers: headers })
        .subscribe(res => {
      
          resolve(res);
          this.ionLoader.hideLoader();
        }, (err) => {
          reject(err);
          this.ionLoader.hideLoader();
        
          
        

        });
    });
   }

  


  deleteData(item) {
    return new Promise((resolve, reject) => {
      //  let headers = new Headers();

      const headers = new HttpHeaders()
      this.ionLoader.presentLoader();
      this.http.delete(environment.apiUrl + item, { headers: headers })
        .subscribe(res => {
          this.ionLoader.hideLoader();
          resolve(res);
        }, (err) => {
          this.ionLoader.hideLoader();
          reject(err);

        });
    });


  }
  //** Valida com api nestjs*/

 async  postTenant(credentials, type) {

     
    return new Promise((resolve, reject) => {


      const headers = new HttpHeaders({
        'X-Tenant-ID':  this.tenant_id.toString() 
      });


      
      this.ionLoader.presentLoader();

      
      this.http.post(environment.apiUrl + type, credentials, { headers: headers })
        .subscribe(res => {
          
          resolve(res);
          this.ionLoader.hideLoader();


        }, (err) => {

          console.log(err);
          
          reject(err);

          
          this.ionLoader.hideLoader();

        });
    });
  }
  async  updateTenant(credentials, type) {
    //  this.tenant_id =  await   this.tenantAccess.getTenantId();

        
        return new Promise((resolve, reject) => {
    
    
          const headers = new HttpHeaders({
            'X-Tenant-ID':  this.tenant_id.toString() 
          });
          this.ionLoader.presentLoader();
          this.http.patch(environment.apiUrl + type, credentials, { headers: headers })
            .subscribe(res => {
              this.ionLoader.hideLoader();
              resolve(res);
    
    
            }, (err) => {
              reject(err);
         
              
              this.ionLoader.hideLoader();
    
            });
        });
      }

      getCEP(item) {
        return new Promise((resolve, reject) => {
          var buscaCep = 'http://viacep.com.br/ws/';
          this.http.get(buscaCep + item + '/json/',)
            .subscribe(res => {
              resolve(res);
            }, (err) => {
              reject(err);
    
            });
        });
    
    
      }
    
      getData(item) {
        return new Promise((resolve, reject) => {
          //  let headers = new Headers();
    
          const headers = new HttpHeaders()
          this.ionLoader.presentLoader();

    
          this.http.get(environment.apiUrl + item, { headers: headers })
            .subscribe(res => {
              this.ionLoader.hideLoader();
              resolve(res);
            }, (err) => {
              this.ionLoader.hideLoader();
              reject(err);
    
            });
        });
    
    
      }


  async Alert(texto = null, titulo = null) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: titulo,
      message: texto,
      buttons: [
        {
          text: 'Ok', handler: () => {
            //   console.log('Confirm Okay');
          }
        }
      ]
    });
    await alert.present();
  }  
}




