import { environment } from './../../environments/environment';

import { StorageService } from './storage.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { Capacitor, Plugins } from '@capacitor/core';
import { ApiService } from 'src/app/service/api.service';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
//import { getAuth, onIdTokenChanged } from "firebase/auth";
//import firebase auth.
import {
  Auth,
  user,
  signOut,
  FacebookAuthProvider,
  signInWithPopup,
  OAuthProvider,
  signInWithCredential,
  signInWithEmailAndPassword,
  updateProfile,
  updateCurrentUser,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
} from '@angular/fire/auth';

// import {
//   SignInWithApple,
//   SignInWithAppleResponse,
//   SignInWithAppleOptions,
// } from '@capacitor-community/apple-sign-in';

import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest, } from '@ionic-native/sign-in-with-apple/ngx';

import { AlertController, Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';

import 'firebase/auth';


import { GooglePlus } from '@ionic-native/google-plus/ngx';


@Injectable({
  providedIn: 'root',
})
export class AuthLoginService {
  public user = null;

  userisloggedin: boolean;
  finishedloading: boolean;
  useremail: string;
  userid: string;


  public isGoogleLogin = false;
  constructor(
    private platform: Platform,
    public authService: ApiService,
    public alertController: AlertController,
    private googlePlus: GooglePlus,
    private router: Router,
    public ionloader: LoaderService,
    public authenticationService: AuthenticationService,
    private fireAuth: AngularFireAuth,
    private signInWithApple: SignInWithApple,



    public storage: StorageService
  ) {



  }
  async ngOnInit() {

    await this.fireAuth.onAuthStateChanged(user => {
      if (user) {
        this.userisloggedin = true
        this.useremail = user.email
        this.userid = user.uid
        this.finishedloading = true
        console.log(JSON.stringify(user))
      } else {
        this.userisloggedin = false
        this.finishedloading = true
      }
    })

  }



  async AppleSignIn() {





    if (this.platform.is('cordova')) {

      const result = await this.signInWithApple.signin({
        requestedScopes: [ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName, ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail]
      })
        .then((res: AppleSignInResponse) => {
          console.log(res);
          const provider = new OAuthProvider('apple.com');
          const credential = provider.credential({ idToken: res.identityToken, rawNonce: '' })

          console.log(credential);
          if (credential) {
            const response = this.onAppleSuccess(credential);
            const fullName = res.fullName;

            if (fullName.givenName) {
              this.storage.set('appleUser', res);
              const appleUser = this.storage.getObject('appleUser');
              const displayName = res.fullName.givenName + "  " + res.fullName.familyName;
              this.updateDisplayName(displayName);
            }
            return response;
          } else {
            this.presentAlert();
          }
        })

        .catch((error: AppleSignInErrorResponse) => {
          // alert(error.code + ' ' + error.localizedDescription);
          console.error(error);
          alert('Error: ' + error);
          return error;
        });



    }
    else {

      // web apple login
      const provider = new OAuthProvider('apple.com');
      const response = await this.fireAuth.signInWithPopup(provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          this.ionloader.hideLoader();
          // The signed-in user info.
          console.log(result.credential);


          return result.user;
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;

          const credential = GoogleAuthProvider.credentialFromError(error);
          return error;
          // ...
        });

      //const result = await this.checkProfile(response);

      return response;

    }

  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Login Failed',
      message: 'Please try again later',
      buttons: ['OK'],
    });
    await alert.present();
  }

  async updateDisplayName(item) {

    const auth = getAuth();
    const user = auth.currentUser;

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;

        updateProfile(user, {
          displayName: item
        }).then(() => {
          console.log("Nome Atualizado");
          // ...
        }).catch((error) => {
          // An error occurred
          // ...
        });
        // ...
      } else {
        // User is signed out
        // ...
      }
    });



  }



  async onAppleSuccess(credential) {


    const response = await this.fireAuth
      .signInWithCredential(credential)
      .then((res) => {
        console.log(res.user);
        return res.user;

      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  }


  async GoogleAuth() {
    const provider = new GoogleAuthProvider();

    let params: any;

    if (this.platform.is('cordova')) {
      if (this.platform.is('android')) {
        params = {
          webClientId: environment.webClientId, offline: true,
        };
      } else {
        params = {};
      }
      console.log(params);

      const result = await this.googlePlus.login(params).then((result) => {
        const { idToken, accessToken } = result;
        const response = this.onLoginSuccess(idToken, accessToken);
        //   console.log(response);
        //    this.Alert(response)
        return response;
      })
        .catch((err) => {
          // alert('error:' + JSON.stringify(err));
          this.Alert(err)
          return err;
        });



      return result;
    } else {
      this.ionloader.presentLoader();
      await this.fireAuth.signInWithPopup(provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          this.ionloader.hideLoader();
          // The signed-in user info.

          const user = result.user;
          //this.Alert("Logado"+token, "loGIN");
          // this.Alert(JSON.stringify(user));
          return result.user;
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          return errorCode;
          // ...
        });

      //const result = await this.checkProfile(response);
      const result = this.getProfileUser();
      console.log(result);


      return result;
    }
  }




  async onLoginSuccess(accessToken, accessSecret) {
    const credential = accessSecret
      ? GoogleAuthProvider.credential(accessToken, accessSecret)
      : GoogleAuthProvider.credential(accessToken);

    const response = await this.fireAuth
      .signInWithCredential(credential)
      .then((sucesss) => {
        this.ionloader.hideLoader();
        this.isGoogleLogin = true;
        this.user = sucesss.user;
        return this.user;
      })
      .catch((error) => {
        this.ionloader.hideLoader();
      });

    return response;
  }

  onLoginError(err) {
    console.log(err);
  }

  async resetPaswordEmail(email) {
    var actionCodeSettings = {};

    this.fireAuth
      .sendPasswordResetEmail(email)
      .then((response) => {
        console.log(response);

        // Password reset email sent!
        this.Alert('Recuperação de Senha Enviada!!');
        // ..
      })
      .catch((error) => {
        console.log(error);

        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  }

  // async facebookAuth() {
  //   var provider = new FacebookAuthProvider();
  //   if (this.platform.is('cordova')) {


  //     this.fb.login(['email'])
  //       .then((response: FacebookLoginResponse) => {
  //         this.onSuccessFacebook(response);
  //         console.log(response.authResponse.accessToken);
  //       }).catch((error) => {
  //         console.log(error);
  //         alert('error:' + error);
  //       });

  //   } else {
  //     const result = await this.fireAuth
  //       .signInWithPopup(provider)
  //       .then((result) => {
  //         const user = result.user;
  //         console.log(user);
  //         return user;
  //       })
  //       .catch((error) => {
  //         // Handle Errors here.
  //         const errorCode = error.code;
  //         const errorMessage = error.message;

  //         console.log(error);
  //         return errorCode;

  //         // ...
  //       });
  //     return result
  //   }


  // }
  //   onSuccessFacebook(res: FacebookLoginResponse) {
  //     // const { token, secret } = res;
  //     var provider = new FacebookAuthProvider();
  //     console.log(res);

  //     // const credential = new FacebookAuthProvider.credential(accessToken)
  //     /*
  //     this.fireAuth.signInWithCredential(credential)
  //       .then((response) => {


  //       });
  // */

  //   }
  async getProfileUser() {
    return this.fireAuth.currentUser;

  }


  async updatePerfil(displayName) {
    const auth = getAuth();
    const user = auth.currentUser;
    updateProfile(user, {
      displayName: displayName
    }).then(() => {
      // Profile updated!
      // ...
    }).catch((error) => {
      // An error occurred
      // ...
    });
  }
  logout() {
    const logout = this.authenticationService.logout();
    this.fireAuth.signOut();
    this.router.navigateByUrl('/', { replaceUrl: true });
  }

  async Alert(texto = null, header = null) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: texto,
      buttons: [
        {
          text: 'ok',
          handler: () => {
            //   console.log('Confirm Okay');
          },
        },
      ],
    });
    await alert.present();
  }
}
