import { AngularFireMessaging } from '@angular/fire/compat/messaging';


import { Injectable } from '@angular/core';



import {  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  token = null
  private _currentMessage: Observable<any>;

  private messaging = this.afMessaging;

  constructor(private afMessaging: AngularFireMessaging) {
    this.messaging.onMessage((payload) => {
      console.log('Mensagem recebida. ', payload);
     // this.currentMessage.next(payload);
    });
  }




  getMessagingToken(): Observable<string | null> {
    return this.afMessaging.requestToken;
  }
  requestPermission() {
    return this.afMessaging.requestPermission;
  }

  updateToken() {
    this.afMessaging.getToken.subscribe((currentToken) => {
      if (currentToken) {
        // Enviar o token do dispositivo para o servidor
        console.log('Token do dispositivo:', currentToken);
      } else {
        // Solicitar permissões para obter o token
        this.requestPermission().subscribe();
      }
    });
  }
  getMessages() {
    return this.afMessaging.messages;
  }

  receiveMessage() {
    this._currentMessage = this.afMessaging.messages;
  }
  getNotification() {
    console.log(this.afMessaging.onBackgroundMessage);

    return this.afMessaging.onMessage;
  }
  deleteToken() {
    if (this.token) {
      this.afMessaging.deleteToken(this.token);
      this.token = null;
    }
  }

  get currentMessage(): Observable<any> {
    return this._currentMessage;
  }

}
