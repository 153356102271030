
import { AuthLoginService } from './../../service/auth-login.service';
import { MenuPage } from './../../pages/menu/menu.page';
import { ModalController } from '@ionic/angular';
import { StorageService } from './../../service/storage.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { ApiService } from '../../service/api.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { ExperimentalPage } from 'src/app/pages/extras/experimental/experimental.page';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  menuList: any;

  detail = {
    "idPerson": "",
    "name": null,
    "email": null,
    "phoneNumber": "",
    "cpf": null,
    "perfil": null,
    "customer": null,
    "staff": null,
    "uuid": null,
    "foto": null
};
  responseData: any;
person : any;
  constructor(
    private router: Router,
    public serviceLogin: AuthLoginService,
    private auth: AuthenticationService,
    private storageService: StorageService,
    public modalController: ModalController,
    public authService: ApiService
  ) {}

  async ngOnInit() {
    const user = await this.storageService.get('login');
    this.detail = JSON.parse(user);

    
    
  
  }
  async openMenu() {
    const modal = await this.modalController.create({
      component: MenuPage,
      cssClass: 'detail-modal',
      breakpoints: [0, 0.4, 1],
      initialBreakpoint: 1,
      
      componentProps: {},
    });
    return await modal.present();
  }
  async experimental() {
    const modal = await this.modalController.create({
      component: ExperimentalPage,
      cssClass: 'detail-modal',
      breakpoints: [0, 0.4, 1],
      initialBreakpoint: 1,
      
      componentProps: {},
    });
    return await modal.present();
  }


  async logout() {
    await this.auth.logout();
    await this.serviceLogin.logout();
    this.router.navigateByUrl('/login', { replaceUrl: true });
  }
}
