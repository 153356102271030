import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { NotificationsService } from './notifications.service';
export interface User {
  name: string;
  role: string;
  permissions: string[];
}
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  subscription: Subscription;
  private currentUser: BehaviorSubject<any> = new BehaviorSubject(null);
  private profileUser: BehaviorSubject<any> = new BehaviorSubject(null);

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  profileRole: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(
    private http: HttpClient,
    public storageService: StorageService,
    private notification: NotificationsService,
    private router: Router
  ) {
    this.loadUser();
  }


  async ionViewWillEnter() {



  }
  async loadUser() {

    const user = await this.storageService.getObject('summer');

  
    if (user) {
      this.notification.registerTopic(user.profile);
      this.isAuthenticated.next(true);
      this.currentUser.next(user);
    } else {
      this.isAuthenticated.next(false);
      this.currentUser.next(false);
    }
  }
  async setLogin(dados) {
    const user = await this.storageService.getObject('summer');
    this.isAuthenticated.next(true);
    this.currentUser.next(user);
    this.router.navigateByUrl('/home', { replaceUrl: true });
  }
  isLoggedIn(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }


  getUser() {
    return this.currentUser.asObservable();
  }

  hasPermission(permissions: string[]): boolean {
     console.log(permissions);

    
  //  for (let permission = 0; permission <= permissions.length; permission++) {
console.log(this.currentUser);

      if (permissions == this.currentUser.value.profile) {
        console.log("tem Acesss");
        
        return true
      } else {
        console.log("Sem acesso");
        return false;
      }
   // }
  }

  logout() {
    this.isAuthenticated.next(false);
    this.storageService.remove('summer');
  }
}
